
import AuthService from "@/auth/AuthService";
import ZFunc from "@/service/ZFunc";
import zCONST from "@/const";
import {Vue} from "vue-class-component";
import { ref, computed } from 'vue';

export default class Login extends Vue{
	AuthS = new AuthService();
	ZF = new ZFunc();
	mLogin : any = {};
	wMessage: any[] = [];
	wMessageCnt: number = 0;
	$cookies: any;

	mounted(): void {
		let wTOKENX = this.$cookies.get("TOKENX");
		let wUSERKY = this.$cookies.get("USERKY");
		let wKENGEN = this.$cookies.get("KENGEN");
		let wUSERNM = this.$cookies.get("USERNM");
		if (wTOKENX && wUSERKY && wKENGEN && wUSERNM) {
			this.$router.push({ name: "OJ1010F" });
		}
	}

	fncLogin(): void {
        console.log("fncLogin");
		this.wMessage = [];
		if(this.fncFldCheck() == false){
			return;
		}
		this.AuthS.fncLogin(this.mLogin).then((result) => {
			if (result[zCONST.cKEY_RESULT] == true) {
				this.$cookies.set("TOKENX", result["TOKENX"]);
				this.$cookies.set("USERKY", result["USERKY"]);
				this.$cookies.set("KENGEN", result["KENGEN"]);
				this.$cookies.set("USERNM", result["USERNM"]);
				if(result[zCONST.cKEY_ALERTS] == zCONST.cALERTS_SUCCESS){
					this.$router.push({ name: "OJ1010F" });
				}else{
					this.$router.push({ name: "FORGOT" });
				}
			} else {
				this.wMessage.push({severity: "warn", content: "ユーザーIDまたはパスワードが正しくありません。", id: this.wMessageCnt++});
			}
		});
	}

	fncFldCheck(): boolean {
		if (this.mLogin.TOKUID == null || this.mLogin.TOKUID == "") {
			this.wMessage.push({severity: "warn", content: "企業IDを入力してください", id: this.wMessageCnt++});
		}
		if (this.mLogin.TOKUID == null || this.mLogin.USERID == "") {
			this.wMessage.push({severity: "warn", content: "ユーザーIDを入力してください", id: this.wMessageCnt++});
		}
		if (this.mLogin.PASSWD == null || this.mLogin.PASSWD == "") {
			this.wMessage.push({severity: "warn", content: "パスワードを入力してください", id: this.wMessageCnt++});
		}
		if(this.wMessage.length > 0){
			return false;
		}else{
			return true;
		}
	}
}
